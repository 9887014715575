import React from 'react'
import "../index.css"
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
export default function Impressum() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll-to-Top beim Laden des Footers
  }, []);
  return (
    <div className='container ps-3 pe-3 marginSection'>
      <Helmet>
        <title>Impressum</title>
        <meta name="robots" content="noindex"/>
      </Helmet>
      <h1 className='smallHeader text-start'>
          Impressum
      </h1>
      <p className='bodytext text-start mt-5'>Informationspflicht laut § 5 TMG.</p>
      <p className='bodytext text-start'>Tom-Julien Erlemann & Simon Stephan NeuralDrive GbR<br/>
      Bernkastelerstraße 14,<br/>
      13088 Berlin,<br/>
      Deutschland</p>
      <p className='bodytext text-start mt-3'>E-Mail: info@neuraldrive.de</p>
      <p className='bodytext text-start mt-3'>Aufsichtsbehörde<br/>
      Berliner Beauftragte für Datenschutz und Informationsfreiheit<br/>
      Webseite der Aufsichtsbehörde
      https://www.datenschutz-berlin.de/<br/>
      Anschrift der Aufsichtsbehörde<br/>
      Friedrichstr. 219 Besuchereingang: Puttkamerstr. 16 – 18 (5. Etage) 10969 Berlin</p>
      <p className='bodytext text-start mt-3'>Berufsbezeichnung: Software Entwickler</p>
      <p className='bodytext text-start mt-3'>Vertreten durch die Gesellschafter:<br/>
      Tom-Julien Erlemann,  Simon Stephan</p>
      <p className='bodytext text-start mt-3'>Berufshaftpflichtversicherung:<br/>
      andsafe Aktiengesellschaft Provinzial-Allee 1 48159 Münster
      Räumliche Geltung: Deutschland</p>
    </div>
  )
}
