import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css"
import logoVis from "../assets/FakeScreenVisuellBackground.png"
import logoKogniNT from "../assets/MockupKogniNeuralMitellipse.png"
import logoMob from "../assets/MockupOverviewNDmitEllipsenMob.png"
import "../index.css"
import "../css/home.css"
import { motion} from 'framer-motion'
import { SchreibUnsVariants, ScaleVariantsOpacity } from './Animationen'
import { useInView } from 'react-intersection-observer'
import CountUp from 'react-countup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faBrain, faStar, faWrench, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { altKogni, altVisSystem,altMain } from './SEO'
import { useTranslation } from 'react-i18next'
import { handleClickGooglePlay,handleClickAppstore } from './Clickables'

const NDAppMob = ()=>{

    const {t} = useTranslation();
    const stars = [faStar, faStar, faStar, faStar, faStar];
    const stringsVisual = [t("ndapp.Sec2_Bulletpoint_1"),t("ndapp.Sec2_Bulletpoint_2"),t("ndapp.Sec2_Bulletpoint_3")]

    //Animation Mobile / Tablet
    const {ref:refMob1,inView:inViewMob1}=useInView({triggerOnce: true});
    const {ref:refMob2,inView:inViewMob2}=useInView({triggerOnce: true, threshold: 0.6});
    const {ref:refMob5,inView:inViewMob5}=useInView({triggerOnce: true,threshold: 0.5});
    const {ref:refMob6,inView:inViewMob6}=useInView({triggerOnce: true,threshold: 0.5});
    const {ref:refMob7,inView:inViewMob7}=useInView({triggerOnce: true,threshold: 0.5});
    const {ref:refMob8,inView:inViewMob8}=useInView({triggerOnce: true});

    //Count Up Animation reference
    const {ref:refCountUpMob,inView:inViewCountUpMob}=useInView({triggerOnce: true,threshold: 0.9});
    const {ref:refCountUpMob2,inView:inViewCountUpMob2}=useInView({triggerOnce: true,threshold: 0.9});
    const {ref:refCountUpMob3,inView:inViewCountUpMob3}=useInView({triggerOnce: true,threshold: 0.9});
    return(
        <div className="container">
              <div className='row justify-content-center d-flex'>
                  <img src={logoMob} alt={altMain} title='NeuralDrive App' className='handy02Mob'/>
              </div>
              <h1 className='text-center text-uppercase bigHeaderMob'>{t("ndapp.Sec1_Header")}</h1>
              <p className='bodytextMob text-center'>{t("ndapp.Sec1_Text")}</p>
              <div className='row justify-content-center d-flex flex-column mb-5'>
                <motion.div ref={refMob1} variants={SchreibUnsVariants} animate={inViewMob1 ? "animate" : "initial"} initial="initial" className='col text-center mt-5'>
                  <button className='buttonLoadMob text-uppercase' onClick={handleClickAppstore}>IOS</button>
                </motion.div>
                <motion.div ref={refMob1} variants={SchreibUnsVariants} animate={inViewMob1 ? "animate" : "initial"} initial="initial" className='col text-center mt-4'>
                  <button className='buttonLoadAndroidMob text-uppercase' onClick={handleClickGooglePlay}>Android</button>
                </motion.div>
              </div>             
              <div className='d-flex flex-column justify-content-between align-items-center overViewBigMob'>
                <div className='overViewBigColumns align-items-center d-flex flex-column justify-content-center'>    
                      <FontAwesomeIcon className='iconsOverViewBig2 mb-3' icon={faEye}/>
                      <p className='fontBoxOverviewUntertitelMob text-uppercase'>{t("ndapp.Sec1_Overview_04")}</p>
                </div>
                <div className='overViewBigColumns align-items-center flex-column d-flex justify-content-center'>
                      <FontAwesomeIcon className='iconsOverViewBig2 mb-3' icon={faWrench}/>
                      <p className='fontBoxOverviewUntertitelMob text-uppercase'>Neural Tools</p>
                </div>
                <div className='overViewBigColumns align-items-center d-flex flex-column justify-content-center mb-5'>
                    <FontAwesomeIcon className='iconsOverViewBig2 mb-3' icon={faBrain}/>
                    <p className='fontBoxOverviewUntertitelMob text-uppercase'>{t("ndapp.Sec1_Overview_06")}</p>
                </div>
              </div>
              <div className='row d-flex align-items-center ps-3 pe-3'>
                <div className='col-12 d-flex boxOverviewMob align-items-center mb-5 flex-column justify-content-center'>
                    <p ref={refCountUpMob} className='fontBoxOverviewHeaderMob'>{inViewCountUpMob && <CountUp start={0} end={25} duration={3.5}/>}+</p>
                    <p className='fontBoxOverviewUntertitelMob'>{t("ndapp.Sec1_Overview_01")}</p>
                </div>
                <div className='col-12 d-flex boxOverviewMob mt-5 mb-5 align-items-center flex-column justify-content-center marginTB30'>
                    <p ref={refCountUpMob2} className='fontBoxOverviewHeaderMob'>{inViewCountUpMob2 && <CountUp start={0} end={10} duration={3.5}/>}+</p>
                    <p className='fontBoxOverviewUntertitel'>{t("ndapp.Sec1_Overview_02")}</p>
                </div>
                <div className='col-12 d-flex boxOverviewMob mt-5 align-items-center flex-column justify-content-center marginTB30'>
                    <p ref={refCountUpMob3} className='fontBoxOverviewHeaderMob'>{inViewCountUpMob3 && <CountUp start={0} end={2} duration={1.5}/>}</p>
                    <p className='fontBoxOverviewUntertitel'>{t("ndapp.Sec1_Overview_03")}</p>
                </div>
            </div>
            <div className='marginSectionMob ps-2'>
              <div className='row d-flex flex-column justify-content-center align-items-start'>
                <div ref={refMob2} className='col'>
                  <h2 className='text-start text-uppercase smallHeaderBereicheMob'>{t("ndapp.Sec2_Header")}</h2>
                  <p className='bodytextMob text-start mb-4'>{t("ndapp.Sec2_Text")}</p>
                    {stringsVisual.map((stringsVisual, index) => (
                        <motion.div
                          className='d-flex flex-row justify-content-start align-items-start mb-4'
                          key={index}
                          initial={{ opacity: 0, x: -100 }} // Startposition unten außerhalb des sichtbaren Bereichs
                          animate={inViewMob2 ? {opacity: 1,x:0, scale: 1} : {opacity: 0, x: -100 }} // Zielposition oben
                          transition={{ duration: 0.5, delay: index * 0.3 }} // Verzögerung basierend auf dem Index
                        > 
                          <div className='arrowHolder d-flex justify-content-center align-items-center'>
                            <FontAwesomeIcon icon={faArrowRight} className='bulletIcons'/>
                          </div>
                          <p className='bodytextBulletsMob text-start'>{stringsVisual}</p>
                        </motion.div>
                      ))}
                </div>
                <motion.div ref={refMob5} variants={ScaleVariantsOpacity} initial="initial" animate={inViewMob5 ? "animate" : "initial"} className='col'>
                  <img src={logoVis} alt={altVisSystem} loading="lazy" title='Visuelles System' className='handy03Mob marginSectionMob'/>
                </motion.div>
              </div>
          </div>
            <div className='marginSectionMob'>
              <div className='row d-flex flex-column'>
                <div className='col'>
                  <h2 className='text-start text-uppercase smallHeaderBereicheMob ps-2'>Neural Tools</h2>
                  <p className='bodytextMob text-start ps-2'>{t("ndapp.Sec3_Text")}</p>
                  <h2 className='text-start text-uppercase smallHeaderBereicheMob mt-5 ps-2'>{t("ndapp.Sec4_Header")}</h2>
                  <p className='bodytextMob text-start ps-2'>{t("ndapp.Sec4_Text")}</p>
                </div>
                <motion.div ref={refMob6} variants={ScaleVariantsOpacity} initial="initial" animate={inViewMob6 ? "animate" : "initial"} className='col mt-5'>
                  <img src={logoKogniNT} alt={altKogni} loading="lazy" title='Kognitionsspiele, Neural Tools' className='img-fluid'/>
                </motion.div>
              </div>
            </div>
            <div className='marginSectionMob'>
              <div className='row justify-content-center'>
                <div className='col'>
                  <h2 className='text-center text-uppercase smallHeaderBereicheMob'>{t("ndapp.Sec5_Header")}</h2>
                  <p className='bodytextMob text-center'>{t("ndapp.Sec5_Text")}</p>
                  <div className='d-flex justify-content-center'>
                    <div ref={refMob7} className='d-flex justify-content-evenly align-items-center breiteStars mt-3'>
                    {stars.map((star, index) => (
                      <motion.div
                        key={index}
                        initial={{ opacity: 0, y: 20, scale: 0.5 }} // Startposition unten außerhalb des sichtbaren Bereichs
                        animate={inViewMob7 ? {opacity: 1,y:0, scale: 1} : {opacity: 0, y: 20, scale: 0.5}} // Zielposition oben
                        transition={{ duration: 0.3, delay: index * 0.2 }} // Verzögerung basierend auf dem Index
                      >
                        <FontAwesomeIcon icon={star} className="starIcon"/>
                      </motion.div>
                    ))}
                    </div>
                  </div>
                  <motion.div ref={refMob8} className="d-flex justify-content-center" variants={SchreibUnsVariants} animate={inViewMob8 ? "animate" : "initial"} initial="initial">
                    <button className='buttonLoadPurchase text-uppercase'>{t("ndapp.Sec5_Button")}</button>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
    );
};

export default NDAppMob;