import React from 'react'
import { Navbar } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import envelope from "../assets/envelope.svg";
import "../css/navbar.css"
import {
    Link
} from "react-router-dom";
import appIcon from "../assets/IconNeuralDrive Kopie.png"
import instagram from "../assets/instagram.svg"
import { altLOGO } from './SEO';
import { useTranslation } from 'react-i18next';


export default function NavBar(){
  const {t}=useTranslation();
    return (
        <div>
        <Navbar collapseOnSelect bg="#121212" variant="dark" expand="md">
            <Container>
                <Navbar.Brand className='navbarBrandText ms-2' href='/' to={"/"}>
                  <img src={appIcon} alt={altLOGO} className='appIcon'/>
                  NeuralDrive
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" className='text-center me-3'/>
                  <Navbar.Collapse id="responsive-navbar-nav" className='text-center'>
                          <Nav className='navBarMarginLeft text-uppercase navbarLinkText ms-auto'>
                              <Nav.Link className='navBarMargin' as={Link} to={"/"}>Home</Nav.Link> 
                              <Nav.Link className='navBarMargin' as={Link} to={"/ndapp"}>NeuralDrive App</Nav.Link> 
                              <Nav.Link className='navBarMargin' as={Link} to={"/contact"}>{t("nav.Contact")}</Nav.Link>            
                          </Nav>
                          <Nav className='d-none d-lg-flex navBarMarginLeft'>
                              <Nav.Link className='socialMediaContainer d-flex navIconMargin' target="_blank" href="https://www.instagram.com/neuraldrive/?hl=de"><img src={instagram} alt="arrow" className='socialIcon'/></Nav.Link>
                              <Nav.Link className='socialMediaContainer d-flex navIconMargin' as={Link} to={"/contact"}><img src={envelope} alt="arrow" className='iconEnvelope'/></Nav.Link>
                          </Nav>
                  </Navbar.Collapse>
            </Container>
        </Navbar>
        </div>
    )
}
