import React, {useState} from 'react'
import "bootstrap/dist/css/bootstrap.min.css"
import { Carousel } from 'react-bootstrap'
import { useInView } from 'react-intersection-observer'
import "../index.css"
import "../css/represent.css"
import { Link } from 'react-router-dom'
import { boxVariants,ScaleVariantsSmooth,SchreibUnsVariants } from './Animationen'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChartSimple,faPen, faDiagramProject, faRocket, faArrowRightFromBracket, faUser} from '@fortawesome/free-solid-svg-icons';
import Lottie from "lottie-react"
import { motion } from 'framer-motion'
import computer from "../assets/Animations/compAnimation.json"
import project from "../assets/Animations/project.json"
import { Helmet } from 'react-helmet-async'
import { handleClickLars,handleClickND,handleClickBA } from './Clickables'
import { interactivity } from './Animationen'
import NeuralDriveRepresentMob from './Representation/RepresentMob'
import NeuralDriveRepresentTab from './Representation/representTab'
import { useTranslation } from 'react-i18next'

export default function NeuralDriveRepresent(){

    const {t}=useTranslation();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [responseDesk,setResponseDesk] = useState();
    const [responseTab,setResponseTab] = useState();
    const [responseMob,setResponseMob] = useState();
    //Animation für Die 3 Boxen
    const {ref:ref1,inView:inView1}=useInView({triggerOnce: true});
    //Animation für Starte Jetzt
    const {ref:btnRef,inView:isInView}=useInView({triggerOnce: true});
    //BoxShadow animation NeuralDrive Team
    const {ref:ref3,inView:isInView3}=useInView({triggerOnce: true,threshold:0.5});
    //Projektboxen
    const {ref:refProjectHeader,inView:isInViewProjectHeader}=useInView({triggerOnce: true,threshold:0.6});
    const {ref:refProjectBoxDesk,inView:isInViewProjectBoxDesk}=useInView({triggerOnce: true,threshold:0.7});
    
    React.useEffect(()=>{
      const handleResize = () => {
      setScreenWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    },[])

    React.useEffect(() => {
      if(screenWidth>=1200){
        setResponseDesk(true);
        setResponseMob(false);
        setResponseTab(false);
      }
      if(576<screenWidth && screenWidth<1200){
        setResponseDesk(false);
        setResponseMob(false);
        setResponseTab(true);
      }
      if(screenWidth<=576){
        setResponseDesk(false);
        setResponseMob(true);
        setResponseTab(false);
      }
    }, [screenWidth]);
 
    return (
      <div>
        <Helmet>
          <title>{t("seo.represent_title")}</title>
          <meta name='description' content={t("seo.represent_metaTitle")} data-rh="true"/>
          <link rel="canonical" hrefLang='https://www.neuraldrive.net'/>
        </Helmet>
        {responseDesk ? (
            /* Desktop Ansicht */ /* Desktop Ansicht */ /* Desktop Ansicht */
            <div className="container-xl sectionOneContainer">
            <div className='row'>
              <div className='col-6 d-flex justify-content-center flex-column'>
                <h1 className='text-start text-uppercase gradHeaderDesk'>{t("represent.digitalSol")}</h1>
                <p className='bodytext text-start m30'>{t("represent.digitalSol_Text")}</p>
                <motion.div animate={{y:0,opacity:1}} initial={{y:20, opacity:0}} transition={{duration: 0.7, delay: 0.3, type:"spring", stiffness: 150}} className='d-flex align-items-start'>
                  <Link to="/contact">
                    <button className='buttonLoadPurchase text-uppercase'>{t("represent.WriteUS")}</button>
                  </Link>
                </motion.div>
              </div>
              <div className='col-6 d-flex align-items-start justify-content-center'>
                <Lottie animationData={computer} loop={false} className="img-fluid"/>
              </div>
            </div>
            <div ref={ref1} className='flex-row d-flex justify-content-between align-items-center marginSection'>           
                <motion.div animate={{scale: inView1 ? 1 : 0}} initial={{scale: 0}} transition={{duration: 0.8 }} className='angebotBoxDesk d-flex flex-column justify-content-start align-items-center pe-3 ps-3'>
                  <div className='iconContainerDesk d-flex justify-content-center align-items-center mt-5'>
                    <FontAwesomeIcon icon={faChartSimple} className="iconsOverViewBig"/>
                  </div>
                  <p className='smallHeaderBereiche m10'>{t("represent.OnlinePresence")}</p>
                  <p className='bodytext text-center'>{t("represent.Represent_Text_Overview_01")}</p>
                </motion.div>              
                <motion.div animate={{scale: inView1 ? 1 : 0}} initial={{scale: 0}} transition={{duration: 0.8, delay: 0.2 }} className='angebotBoxDesk d-flex flex-column justify-content-start align-items-center pe-3 ps-3'>
                  <div className='iconContainerDesk d-flex justify-content-center align-items-center mt-5'>
                    <FontAwesomeIcon icon={faPen} className="iconsOverViewBig"/>
                  </div>
                  <p className='smallHeaderBereiche m10'>Design</p>
                  <p className='bodytext text-center'>{t("represent.Represent_Text_Overview_02")}</p>
                </motion.div>          
                <motion.div animate={{scale: inView1 ? 1 : 0}} initial={{scale: 0}} transition={{duration: 0.8,delay: 0.4 }} className='angebotBoxDesk d-flex flex-column justify-content-start align-items-center pe-3 ps-3'>
                  <div className='iconContainerDesk d-flex justify-content-center align-items-center mt-5'>
                    <FontAwesomeIcon icon={faDiagramProject} className="iconsOverViewBig"/>
                  </div>
                  <p className='smallHeaderBereiche m10'>{t("represent.Application")}</p>
                  <p className='bodytext text-center'>{t("represent.Represent_Text_Overview_03")}</p>
                </motion.div>
          </div>
          <div className='marginSection'/>
          <div className='row'>
              <div className='col-5 d-flex align-items-center justify-content-center'>
                <Lottie animationData={project} loop={true} interactivity={interactivity} className="img-fluid"/>
              </div>
              <div className='col-7'>
                <h2 className='text-start text-uppercase HeaderDesk'>{t("represent.Represent_Header_Sec2_01")}<br/>{t("represent.Represent_Header_Sec2_02")}</h2>
                <p className='bodytext text-start m30'>{t("represent.Represent_Text_Sec2")}</p>
                <motion.div ref={btnRef} animate={{y: isInView ? 0 : 20,opacity: isInView ? 1 : 0}} initial={{y:20, opacity:0}} transition={{duration: 0.7, delay: 0.3, type:"spring", stiffness: 150}} className='d-flex align-items-start'> 
                    <Link to="/contact">                
                      <button className='buttonCTA d-flex flex-row justify-content-center align-items-center text-uppercase'>
                        {t("represent.Represent_Button_Sec2")}
                        <FontAwesomeIcon icon={faRocket} style={{ marginLeft: '15px', color:"white"}} />
                      </button>
                    </Link>
                </motion.div>
              </div>
            </div>
            <div className='marginSection'/>
            <div className='row'>
              <div className='col-12 align-items-center d-flex flex-column'>
                <motion.h2 ref={refProjectHeader} variants={SchreibUnsVariants} animate={isInViewProjectHeader ? "animate" : "initial"} initial="initial" className='text-center text-uppercase HeaderDesk'>NeuralDrive {t("represent.Represent_Header_Sec3")}</motion.h2>
              </div>
              <motion.div ref={refProjectBoxDesk} animate={isInViewProjectBoxDesk ? "animate" : "initial"} variants={ScaleVariantsSmooth} >
                <Carousel indicators={false} className='align-items-center mt-5'>
                  <Carousel.Item>
                  <div className='btnProjects d-flex flex-column justify-content-evenly align-items-center ms-5 me-5'>
                      <h4 className='smallHeaderBereiche text-center mt-3'>NeuralDrive App</h4>
                      <p className='bodytext text-center mt-3 w-50'>
                          {t("represent.Represent_Text_Sec3_NDApp")}
                      </p>
                      <button className='buttonProjects d-flex flex-row justify-content-center align-items-center mt-4' onClick={handleClickND}>
                          App
                          <FontAwesomeIcon icon={faArrowRightFromBracket} style={{ marginLeft: '15px'}} className="iconProjectBtn"/>
                      </button>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                  <div className='btnProjects d-flex flex-column justify-content-evenly align-items-center ms-5 me-5'>
                    <h4 className='smallHeaderBereiche text-center mt-3'>Highflyerz Academy</h4>
                    <p className='bodytext text-center w-50 mt-3'>
                      {t("represent.Represent_Text_Sec3_HF")}
                    </p>
                    <button className='buttonProjects d-flex flex-row justify-content-center align-items-center mt-4'>
                      Web-App
                      <FontAwesomeIcon icon={faArrowRightFromBracket} style={{ marginLeft: '15px'}} className="iconProjectBtn" />
                    </button>
                  </div>
                  </Carousel.Item>
                  <Carousel.Item>
                  <div className='btnProjects d-flex flex-column justify-content-evenly align-items-center ms-5 me-5'>
                    <h4 className='smallHeaderBereiche text-center mt-3'>Lars Grießig - Neurotraining</h4>
                    <p className='bodytext text-center w-50 mt-3'>
                      {t("represent.Represent_Text_Sec3_LG")}
                    </p>
                    <button className='buttonProjects d-flex flex-row justify-content-center align-items-center mt-4' onClick={handleClickLars}>
                      Website
                      <FontAwesomeIcon icon={faArrowRightFromBracket} style={{ marginLeft: '15px'}} className="iconProjectBtn"/>
                    </button>
                  </div>
                  </Carousel.Item>
                  <Carousel.Item>
                  <div className='btnProjects d-flex flex-column justify-content-evenly align-items-center ms-5 me-5'>
                    <h4 className='smallHeaderBereiche text-center mt-3'>Bewegungsarchitekten</h4>
                    <p className='bodytext text-center w-50 mt-3'>
                      {t("represent.Represent_Text_Sec3_BA")}
                    </p>
                    <button className='buttonProjects d-flex flex-row justify-content-center align-items-center mt-4' onClick={handleClickBA}>
                      Website
                      <FontAwesomeIcon icon={faArrowRightFromBracket} style={{ marginLeft: '15px'}} className="iconProjectBtn"/>
                    </button>
                  </div>
                  </Carousel.Item>
              </Carousel>
            </motion.div>
            </div>
            <h2 className='text-center HeaderDesk mbot50 marginSection'>NeuralDrive Team</h2>
            <div className='d-flex justify-content-center'>
              <div ref={ref3} className='d-flex justify-content-between align-items-start personContainer w-75'>
                  <div className='d-flex flex-column justify-content-start align-items-center'>
                    <motion.div variants={boxVariants} animate={isInView3 ? "animate" : "initial"} initial="initial" className='d-flex justify-content-center align-items-center imgPersonContainer'>
                      <FontAwesomeIcon icon={faUser} style={{color:"white"}} className="imgPerson"/>
                    </motion.div>
                    <p className='smallHeaderBereiche text-center m20'>Tom Erlemann</p>
                    <p className='bodytext text-center'>UI Design<br/>Web Design</p>
                  </div>
                  <div animate={{scale: 1}} initial={{scale: 0}} transition={{duration: 0.8 }} className='d-flex flex-column justify-content-start align-items-center'>
                    <motion.div variants={boxVariants} animate={isInView3 ? "animate" : "initial"} initial="initial" className='d-flex justify-content-center align-items-center imgPersonContainer'>
                      <FontAwesomeIcon icon={faUser} style={{color:"white"}} className="imgPerson"/>
                    </motion.div>
                    <p className='smallHeaderBereiche text-center m20'>Simon Stephan</p>
                    <p className='bodytext text-center'>{t("represent.Represent_Text_Sec4_SS_01")}<br/>{t("represent.Represent_Text_Sec4_SS_02")}</p>
                  </div>
                  <div animate={{scale: 1}} initial={{scale: 0}} transition={{duration: 0.8 }} className='d-flex flex-column justify-content-start align-items-center'>
                    <motion.div variants={boxVariants} animate={isInView3 ? "animate" : "initial"} initial="initial" className='d-flex justify-content-center align-items-center imgPersonContainer'>
                      <FontAwesomeIcon icon={faUser} style={{color:"white"}} className="imgPerson"/>
                    </motion.div>
                    <p className='smallHeaderBereiche text-center m20'>Gadschi Saidov</p>
                    <p className='bodytext text-center'>Corporate Design</p>
                    <p className='bodytext text-center'>3D Design</p>
                  </div>
              </div>
            </div>
          </div>
        /* Tablet Ansicht */ /* Tablet Ansicht */ /* Tablet Ansicht */
        ):responseTab ?(
          <NeuralDriveRepresentTab/>
        /* Mobile Ansicht */ /* Mobile Ansicht */ /* Mobile Ansicht */
        ):responseMob ?(
          <NeuralDriveRepresentMob/>
        ):null}
        </div>
    )
  }
