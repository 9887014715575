import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import NotFound from "./components/Navigation/NotFound";
import Impressum from './components/Impressum';
import Datenschutz from './components/Datenschutz';
import NeuralDriveRepresent from "./components/Representation";
import Neuraldriveapp from "./components/NeuralDriveApp";
import Contact from "./components/Contact"

function App() {
  return (
    <Router>
      <div className='App'>
        <NavBar/>
          <Routes>
              <Route exact path="/" element={<NeuralDriveRepresent/>}/>
              <Route exact path="/ndapp" element={<Neuraldriveapp/>}/>
              <Route exact path="/contact" element={<Contact/>}/>
              <Route exact path="/imp" element={<Impressum/>}/>
              <Route exact path="/dschutz" element={<Datenschutz/>}/>
              <Route path="*" element={<NotFound />} />
            </Routes>
         <Footer/>
      </div>
    </Router>
  );
}

export default App;
