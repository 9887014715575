import React, {useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css"
import "../index.css"
import "../css/represent.css"
import "../css/contact.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { Alert} from 'react-bootstrap';
import { boxVariantsBlue, SchreibUnsVariants } from './Animationen';
import { motion } from 'framer-motion';
import { faEnvelopeCircleCheck, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useInView } from 'react-intersection-observer';
import ContactMob from './Contact/ContactMob';
import ContactTab from './Contact/ContactTab';
import simonBild from "../assets/Simonzugeschnitten.jpg"
import gsBild from "../assets/GSZugeschnitten.png"
import tomBild from "../assets/tomBild.png"
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next';


export default function Contact (){

    const {t}=useTranslation();
    const {ref:refContact1,inView:inViewContact1}=useInView({triggerOnce: true,threshold: 0.8});
    const {ref:refContact2,inView:inViewContact2}=useInView({triggerOnce: true,threshold: 0.8});
    const {ref:refContact3,inView:inViewContact3}=useInView({triggerOnce: true,threshold: 0.8});
    const {ref:refContact4,inView:inViewContact4}=useInView({triggerOnce: true});
  
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [responseDesk,setResponseDesk] = useState();
    const [responseTab,setResponseTab] = useState();
    const [responseMob,setResponseMob] = useState();


    const handleClickEmaildesk =()=>{
      window.location.href = "mailto:info@neuraldrive.de"
    }

    React.useEffect(()=>{
      const handleResize = () => {
      setScreenWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    },[])

    React.useEffect(() => {
      if(screenWidth>=1200){
        setResponseDesk(true);
        setResponseMob(false);
        setResponseTab(false);
      }
      if(580<screenWidth && screenWidth<1200){
        setResponseDesk(false);
        setResponseMob(false);
        setResponseTab(true);
      }
      if(screenWidth<=580){
        setResponseDesk(false);
        setResponseMob(true);
        setResponseTab(false);
      }
    }, [screenWidth]);

  return (
    <div>
    <Helmet>
      <title>Kontakt NeuralDrive</title>
      <meta name='description' content='Möchtest du dein Website Projekt oder deine App Idee endlich umsetzen lassen? Dann schreib uns.'/>
    </Helmet>
    {responseDesk ? (
      /* Desktop Ansicht */ /* Desktop Ansicht */ /* Desktop Ansicht */
      <div className="container marginSection">
        <div className='row'>
          <div  className='col-9'>
            <h1 className='text-start text-uppercase HeaderDesk'>{t("contact.Sec1_Header")}</h1>
            <p className='bodytext text-start mt-4 mb-4'>{t("contact.Sec1_Text")}</p>
              <motion.div ref={refContact4} variants={SchreibUnsVariants} animate={inViewContact4 ? "animate" : "initial"} initial="initial">
                <button onClick={handleClickEmaildesk} className='buttonForm d-flex flex-row justify-content-center align-items-center text-uppercase mb-5'>
                  {t("contact.Sec1_Button")}
                  <FontAwesomeIcon icon={faPaperPlane} style={{ marginLeft: '15px', color:"white"}} />
                </button>
              </motion.div>
          </div>
          <div className='col-12 mb-3 mt-5'>
          <div ref={refContact1} className='d-flex flex-row justify-content-start align-items-center'>
                <motion.div variants={boxVariantsBlue} animate={inViewContact1 ? "animate" : "initial"} initial="initial" className='d-flex justify-content-center align-items-center imgPersonContainer'>
                  <img src={tomBild} alt='none' className='img-fluid'/>
                </motion.div>
              <div className='d-flex flex-column justify-content-center align-items-start ms-5'>
                <p className='smallHeaderBereiche text-start'>Tom-Julien Erlemann</p>
                <div className='d-flex flex-row mt-3 align-items-center'>
                    <FontAwesomeIcon icon={faEnvelopeCircleCheck} className="envelopeIcon me-3"/>
                    <p className='bodytext text-start'>tj.erlemann@neuraldrive.de</p>
                  </div>
              </div>
            </div>
          </div>
          <div className='col-12 mt-5 mb-3'>
            <div ref={refContact2} className='d-flex flex-row justify-content-start align-items-center'>
                <motion.div variants={boxVariantsBlue} animate={inViewContact2 ? "animate" : "initial"} initial="initial" className='d-flex justify-content-center align-items-center imgPersonContainer'>
                  <img src={simonBild} alt='none' className='img-fluid mt-5'/>
                </motion.div>
                <div className='d-flex flex-column justify-content-center align-items-start ms-5'>
                  <p className='smallHeaderBereiche text-start'>Simon Stephan</p>
                  <div className='d-flex flex-row mt-3 align-items-center'>
                    <FontAwesomeIcon icon={faEnvelopeCircleCheck} className="envelopeIcon me-3"/>
                    <p className='bodytext text-start'>s.stephan@neuraldrive.de</p>
                  </div>
                </div>
              </div>
          </div>
          <div className='col-12 mt-5'>
            <div ref={refContact3} className='d-flex flex-row justify-content-start align-items-center'>
                <motion.div variants={boxVariantsBlue} animate={inViewContact3 ? "animate" : "initial"} initial="initial" className='d-flex justify-content-center align-items-center imgPersonContainer'>
                  <img src={gsBild} alt='none' className='img-fluid'/>
                </motion.div>
                <div className='d-flex flex-column justify-content-center align-items-start ms-5'>
                  <p className='smallHeaderBereiche text-start'>Gadschi Saidov</p>
                  <div className='d-flex flex-row mt-3 align-items-center'>
                    <FontAwesomeIcon icon={faEnvelopeCircleCheck} className="envelopeIcon me-3"/>
                    <p className='bodytext text-start'>g.saidov@neuraldrive.de</p>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      /* Tablet Ansicht */ /* Tablet Ansicht */ /* Tablet Ansicht */
      ):responseTab ?(
        <div className="container marginSection">
          <ContactTab/>
        </div>
      /* Mobile Ansicht */ /* Mobile Ansicht */ /* Mobile Ansicht */
      ):responseMob ?( 
        <div className="container marginSectionMob">
          <ContactMob/>
        </div>
      ):null}
    </div>
  );
};