import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css"
import { Helmet } from 'react-helmet-async';

const NotFound = () => (
  <div>
    <Helmet>
        <title>Not Found</title>
        <meta name="robots" content="noindex"/>
      </Helmet>
      <div className='d-flex justify-content-center align-items-center flex-column'>
        <h1 className='text-white text-center'>404 - Not Found!</h1>
      </div>
  </div>
  
);

export default NotFound;