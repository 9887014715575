import React from 'react'
import "../../index.css"
import "../../css/contact.css"
import "../../css/represent.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { boxVariantsBlue, SchreibUnsVariants } from '../Animationen'
import { faEnvelopeCircleCheck, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import simonBild from "../../assets/Simonzugeschnitten.jpg"
import gsBild from "../../assets/GSZugeschnitten.png"
import tomBild from "../../assets/tomBild.png"
import { useTranslation } from 'react-i18next'

export default function ContactTab() {

    const {t}=useTranslation();
    const {ref:refContactTab1,inView:inViewContactTab1}=useInView({triggerOnce: true});
    const {ref:refContactTab2,inView:inViewContactTab2}=useInView({triggerOnce: true});
    const {ref:refContactTab3,inView:inViewContactTab3}=useInView({triggerOnce: true});
    const {ref:refContactTab4,inView:inViewContactTab4}=useInView({triggerOnce: true});

    const handleClickEmailTab =()=>{
        window.location.href = "mailto:info@neuraldrive.de"
      }

  return (
        <div className='row'>
          <div  className='col-12 d-flex justify-content-center align-items-center flex-column'>
            <h1 className='text-center text-uppercase HeaderDesk'>{t("contact.Sec1_Header")}</h1>
            <p className='bodytext text-center mt-4 mb-4'>{t("contact.Sec1_Text")}</p>
            <motion.div ref={refContactTab4} variants={SchreibUnsVariants} animate={inViewContactTab4 ? "animate" : "initial"} initial="initial">
                <button onClick={handleClickEmailTab} className='buttonForm d-flex flex-row justify-content-center align-items-center text-uppercase mb-5'>
                  {t("contact.Sec1_Button")}
                  <FontAwesomeIcon icon={faPaperPlane} style={{ marginLeft: '15px', color:"white"}} />
                </button>
              </motion.div>
          </div>
          <div className='col-12 mt-5 mb-5 d-flex justify-content-center align-items-center flex-column'>
            <div ref={refContactTab1} className='d-flex flex-column justify-content-center align-items-center'>
                <motion.div variants={boxVariantsBlue} animate={inViewContactTab1 ? "animate" : "initial"} initial="initial" className='d-flex justify-content-center align-items-center imgPersonContainer'>
                  <img src={tomBild} alt='none' className='img-fluid'/>
                </motion.div>
                <div className='d-flex flex-column justify-content-center align-items-center mt-5'>
                  <p className='smallHeaderBereiche text-center'>Tom-Julien Erlemann</p>
                  <div className='d-flex flex-row mt-3 align-items-center'>
                    <FontAwesomeIcon icon={faEnvelopeCircleCheck} className="envelopeIcon me-3"/>
                    <p className='bodytext text-center'>tj.erlemann@neuraldrive.de</p>
                  </div>
                </div>
              </div>
          </div>
          <div className='col-12 mt-5 mb-5 d-flex justify-content-center align-items-center flex-column'>
            <div ref={refContactTab2} className='d-flex flex-column justify-content-center align-items-center'>
                <motion.div variants={boxVariantsBlue} animate={inViewContactTab2 ? "animate" : "initial"} initial="initial" className='d-flex justify-content-center align-items-center imgPersonContainer'>
                  <img src={simonBild} alt='none' className='img-fluid'/>
                </motion.div>
                <div className='d-flex flex-column justify-content-center align-items-center mt-5'>
                  <p className='smallHeaderBereiche text-center'>Simon Stephan</p>
                  <div className='d-flex flex-row mt-3 align-items-center'>
                    <FontAwesomeIcon icon={faEnvelopeCircleCheck} className="envelopeIcon me-3"/>
                    <p className='bodytext text-center'>s.stephan@neuraldrive.de</p>
                  </div>
                </div>
              </div>
          </div>
          <div className='col-12 mt-5 d-flex justify-content-center align-items-center flex-column'>
            <div ref={refContactTab3} className='d-flex flex-column justify-content-center align-items-center'>
                <motion.div variants={boxVariantsBlue} animate={inViewContactTab3 ? "animate" : "initial"} initial="initial" className='d-flex justify-content-center align-items-center imgPersonContainer'>
                  <img src={gsBild} alt='none' className='img-fluid'/>
                </motion.div>
                <div className='d-flex flex-column justify-content-center align-items-center mt-5'>
                  <p className='smallHeaderBereiche text-center'>Gadschi Saidov</p>
                  <div className='d-flex flex-row mt-3 align-items-center'>
                    <FontAwesomeIcon icon={faEnvelopeCircleCheck} className="envelopeIcon me-3"/>
                    <p className='bodytext text-center'>g.saidov@neuraldrive.de</p>
                  </div>
                </div>
              </div>
          </div>
        </div>
  )
}
