export const boxVariants = {
    initial: {
      boxShadow: "0 0 0px rgba(201, 39, 30, 0.4),0 0 0px rgba(201, 39, 30, 0.4)",
    },
    animate: {
      boxShadow: "0 0 35px rgba(201, 39, 30, 0.4),0 0 65px rgba(201, 39, 30, 0.4)",
      transition: {
        duration: 1.5,
        type: "tween",
      },
    },
  };

  export const boxVariantsBlue = {
    initial: {
      boxShadow: "0 0 0px rgba(0, 123, 255, 0.4),0 0 0px rgba(0, 123, 255, 0.4)",
      opacity: 0
    },
    animate: {
      opacity: 1,
      boxShadow: "0 0 20px rgba(0, 123, 255, 0.4),0 0 45px rgba(0, 123, 255, 0.4)",
      transition: {
        duration: 1.5,
        type: "tween",
      },
    },
  };

export const ScaleVariants={
    initial:{
      scale: 0
    },
    animate:{
      scale: 1,
      transition:{
        duration: 0.3,
        type: "spring",
        stiffness: 180,
        damping: 20
      }
    }
  };

export const ScaleVariantsOpacity={
  initial:{
    scale: 0.8,
    opacity: 0
  },
  animate:{
    scale: 1,
    opacity: 1,
    transition:{
      duration: 0.8,
      type: "tween",
    }
  }
};

export const ScaleVariantsStatic={
  initial:{
    scale: 0.7,
    y: 50,
    opacity: 0
  },
  animate:{
    scale: 1,
    opacity: 1,
    y:0,
    transition:{
      duration: 0.6,
      type: "tween",
    }
  }
};

export const ScaleVariantsSmooth={
    initial:{
      opacity: 0,
      y:50
    },
    animate:{
      opacity: 1,
      y:0,
      transition:{
        duration: 0.7,
      }
    }
  };

  export const ScaleVariantsRight={
    initial:{
      opacity: 0,
      x:100
    },
    animate:{
      opacity: 1,
      x:0,
      transition:{
        duration: 0.7,
        delay:0.3
      }
    }
  };

export const SchreibUnsVariants = {
    initial: {
      opacity: 0,
      y: 50
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.65,
        type:"tween"
      },
    },
  };

  export const KogniDesk = {
    initial: {
      opacity: 0,
      x: 100
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.9,
        type: "tween"
      },
    },
  };

export const Stars ={
  initial: {
    opacity: 0,
    y: 20
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.8,
      type: "tween"
    },
  },
}

export const BulletPoints ={
  initial: {
    opacity: 0,
    x: -100
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.8,
      type: "tween",
      when: "beforeChildren",
      staggerChildren: 0.3,
    },
  },
}

export const interactivity = {
  mode: "scroll",
  actions: [
    {
      visibility: [0, 2],
      type: "seek",
      frames: [0, 200],
    },
  ],
};


