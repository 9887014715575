import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css"
import "../index.css"
import "../css/footer.css"
import { Link } from 'react-router-dom'
import envelope from "../assets/envelope.svg";
import instagram from "../assets/instagram.svg"
import { useTranslation } from 'react-i18next'

export default function Footer() {
    let date = new Date().getFullYear();
    const {t}=useTranslation();
  return (
    <div className='container mt-auto'>
        <div className='marginSection'/>
        <div className="d-md-none">
            <div className='row'>
                <div className='col-6 d-flex flex-row justify-content-end align-items-center mt-3'>
                    <Link className='footerText me-1' to="/imp">
                        <p>Impressum</p>
                    </Link>
                </div>
                <div className='col-6 d-flex justify-content-start align-items-center mt-3'>
                    <Link className='footerText ms-1' to="/dschutz">
                        <p>{t("nav.Privacy_Policy")}</p>

                    </Link>
                </div>
            </div>
            <div className='d-flex justify-content-center align-items-center mt-4'>
                <Link className='socialMediaContainerFooter me-3 d-flex' target="_blank" href="https://www.instagram.com/neuraldrive/?hl=de"><img src={instagram} alt="arrow" className='socialIconFooter'/></Link>
                <Link className='socialMediaContainerFooter ms-3 d-flex' as={Link} to={"/contact"}><img src={envelope} alt="arrow" className='iconEnvelopeFooter'/></Link>
            </div>
            <div className='d-flex justify-content-center align-items-center mt-4 pb-3'>
                <p className='text-center bodytext'>
                    &copy; Copyright {date} NeuralDrive - {t("nav.Rights")}
                </p>
            </div>
        </div>
        <div className="d-none d-md-block">
            <div className='row'>
                <div className='col-md-6 d-flex justify-content-end align-items-center'>
                    <Link className='footerText me-2' to="/imp">
                        <p>Impressum</p>
                    </Link>
                </div>
                <div className='col-md-6 d-flex justify-content-start align-items-center'>
                    <Link className='footerText ms-2' to="/dschutz">
                        <p>{t("nav.Privacy_Policy")}</p>
                    </Link>
                </div>
            </div>
            <div className='d-flex justify-content-center align-items-center mt-4'>
                <Link className='socialMediaContainerFooter me-3 d-flex' target="_blank" href="https://www.instagram.com/neuraldrive/?hl=de"><img src={instagram} alt="arrow" className='socialIconFooter'/></Link>
                <Link className='socialMediaContainerFooter ms-3 d-flex' as={Link} to={"/contact"}><img src={envelope} alt="arrow" className='iconEnvelopeFooter'/></Link>
            </div>
            <div className='d-flex justify-content-center align-items-center mt-4 pb-5'>
                <p className='text-center bodytext'>
                    &copy; Copyright {date} NeuralDrive - {t("nav.Rights")}
                </p>
            </div>
        </div>
    </div>
  )
}
