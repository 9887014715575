import React, { useState } from 'react'
import "bootstrap/dist/css/bootstrap.min.css"
import logo from "../assets/MockupOverviewNDmitEllipsen.png"
import logoVis from "../assets/FakeScreenVisuellBackground.png"
import logoKogniNT from "../assets/MockupKogniNeuralMitellipse.png"
import "../index.css"
import "../css/home.css"
import { motion} from 'framer-motion'
import { SchreibUnsVariants,ScaleVariantsRight,ScaleVariantsStatic} from './Animationen'
import { useInView } from 'react-intersection-observer'
import CountUp from 'react-countup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faScrewdriverWrench, faBrain, faStar,faArrowRight } from '@fortawesome/free-solid-svg-icons'
import NDAppMob from './NeuralDriveAppMobile'
import NDAppTab from './NeuralDriveAppTablet'
import { Helmet } from 'react-helmet-async'
import { altKogni, altVisSystem,altMain } from './SEO'
import { useTranslation } from 'react-i18next'
//import { useTranslation } from 'react-i18next'
import { handleClickAppstore, handleClickGooglePlay } from './Clickables'

export default function Neuraldriveapp(){

    //Screen Resizing
    const {t} = useTranslation();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [responseDesk,setResponseDesk] = useState();
    const [responseTab,setResponseTab] = useState();
    const [responseMob,setResponseMob] = useState();
    const stringsVisual = [t("ndapp.Sec2_Bulletpoint_1"),t("ndapp.Sec2_Bulletpoint_2"),t("ndapp.Sec2_Bulletpoint_3")];
    const stars = [faStar, faStar, faStar, faStar, faStar];
    

    //Animationen Desktop
    const {ref:ref1,inView:inView1}=useInView({triggerOnce: true});
    const {ref:ref2,inView:inView2}=useInView({triggerOnce: true});
    const {ref:ref4,inView:inView4}=useInView({triggerOnce: true,threshold: 0.7});
    //const {ref:ref5,inView:inView5}=useInView({triggerOnce: true,threshold: 0.8});
    const {ref:ref6,inView:inView6}=useInView({triggerOnce: true,threshold: 0.8});
    const {ref:ref7,inView:inView7}=useInView({triggerOnce: true,threshold: 0.5});
    const {ref:ref8,inView:inView8}=useInView({triggerOnce: true,threshold: 0.5});

    //Count Up Animation reference
    const {ref:refCountUp,inView:inViewCountUp}=useInView({triggerOnce: true,threshold: 0.9});

   /*
    const refVisualSystem = useRef(null);
    const {scrollYProgress} = useScroll({
      target: refVisualSystem,
      offset: ["start end", "end start"],
      useLayoutEffect: false
    });
    const opacity = useTransform(scrollYProgress,[0,0.5,1],[0,1,0]);
    const scale = useTransform(scrollYProgress,[0,0.65,1],[1,1,0.8]);*/

    //Screen resizing
    React.useEffect(()=>{
        const handleResize = () => {
        setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    },[]);

    React.useEffect(() => {
        if(screenWidth>=1200){
          setResponseDesk(true);
          setResponseMob(false);
          setResponseTab(false);
        }
        if(580<screenWidth && screenWidth<1200){
          setResponseDesk(false);
          setResponseMob(false);
          setResponseTab(true);
        }
        if(screenWidth<=580){
          setResponseDesk(false);
          setResponseMob(true);
          setResponseTab(false);
        }
    }, [screenWidth]);

    return (
      <div>
        <Helmet>
          <title>{t("seo.ndapp_title")}</title>
          <meta name='description' content={"ndapp.represent_metaTitle"} data-rh="true"/>
          <link rel="canonical" hrefLang='https://www.neuraldrive.net/ndapp'/>
        </Helmet>
        {responseDesk ? (
          <div className='container sectionOneContainer'>
          <div className='row'>
            <div className='col-7'>
              <h1 className='text-start text-uppercase HeaderDeskND mt-5 w-75'>{t("ndapp.Sec1_Header")}</h1>
              <p className='bodytext text-start'>{t("ndapp.Sec1_Text")}</p>
              <div className='d-flex flex-direction-start align-items-center flex-row'>
              <motion.div ref={ref1} variants={SchreibUnsVariants} animate={inView1 ? "animate" : "initial"} initial="initial" className="d-flex justify-content-start align-items-center text-start">
                  <button className='buttonLoad text-uppercase mt-4 me-3' onClick={handleClickAppstore}>IOS Download</button>
              </motion.div>
              <motion.div ref={ref1} variants={SchreibUnsVariants} animate={inView1 ? "animate" : "initial"} initial="initial" className="d-flex justify-content-start align-items-center text-start">
                  <button className='buttonLoadAndroid text-uppercase mt-4' onClick={handleClickGooglePlay}>Android Download</button>
              </motion.div>
              </div>
            </div>
            <motion.div ref={ref2} variants={ScaleVariantsRight} animate={inView2 ? "animate" : "initial"} initial="initial" className='col-5'>
              <img src={logo} alt={altMain} title='NeuralDrive App' className='handy01 float-left'/>
            </motion.div>
          </div>
          <div className='d-flex justify-content-between marginSection'>
            <motion.div ref={refCountUp} className='d-flex boxOverview align-items-center flex-column justify-content-center'>
                <p className='fontBoxOverviewHeader'>{inViewCountUp && <CountUp start={0} end={25} duration={3.5}/>}+</p>
                <p className='fontBoxOverviewUntertitel'>{t("ndapp.Sec1_Overview_01")}</p>
            </motion.div>
            <motion.div className='d-flex boxOverview align-items-center flex-column justify-content-center'>
                <p className='fontBoxOverviewHeader'>{inViewCountUp && <CountUp start={0} end={10} duration={3.5}/>}+</p>
                <p className='fontBoxOverviewUntertitel'>{t("ndapp.Sec1_Overview_02")}</p>
            </motion.div>
            <motion.div className='d-flex boxOverview align-items-center flex-column justify-content-center'>
                <p className='fontBoxOverviewHeader'>{inViewCountUp && <CountUp start={0} end={2} duration={3.5}/>}</p>
                <p className='fontBoxOverviewUntertitel'>{t("ndapp.Sec1_Overview_03")}</p>
            </motion.div>
          </div>
          <div ref={ref4} className='d-flex flex-row justify-content-between align-items-center overViewBig mt-5'>
            <motion.div variants={ScaleVariantsStatic} animate={inView4 ? "animate" : "initial"} initial="initial" className='overViewBigColumns align-items-center d-flex flex-column justify-content-center align-items-center'>    
                  <FontAwesomeIcon className='iconsOverViewBig2 mb-3' icon={faEye}/>
                  <p className='fontBoxOverviewUntertitel text-uppercase'>{t("ndapp.Sec1_Overview_04")}</p>
            </motion.div>
            <motion.div variants={ScaleVariantsStatic} animate={inView4 ? "animate" : "initial"} initial="initial" className='overViewBigColumns align-items-center flex-column d-flex justify-content-center'>
                  <FontAwesomeIcon className='iconsOverViewBig2 mb-3' icon={faScrewdriverWrench}/>
                  <p className='fontBoxOverviewUntertitel text-uppercase'>Neural Tools</p>
            </motion.div>
            <motion.div variants={ScaleVariantsStatic} animate={inView4 ? "animate" : "initial"} initial="initial" className='overViewBigColumns align-items-center d-flex flex-column justify-content-center'>
                <FontAwesomeIcon className='iconsOverViewBig2 mb-3' icon={faBrain}/>
                <p className='fontBoxOverviewUntertitel text-uppercase'>{t("ndapp.Sec1_Overview_06")}</p>
            </motion.div>
          </div>
          <div ref={ref7} className='marginSection'>
            <div className='row'>
              <div className='col-5'>
                <img src={logoVis} alt={altVisSystem} loading="lazy" title='Visuelles System' className='handy02 float-left'/>
              </div>
              <div className='col-7'>
                <h2 className='text-start text-uppercase smallHeaderBereiche'>{t("ndapp.Sec2_Header")}</h2>
                <p className='bodytext text-start mb-4'>{t("ndapp.Sec2_Text")}</p>
                {stringsVisual.map((stringsVisual, index) => (
                        <motion.div
                          className='d-flex flex-row justify-content-start align-items-center mb-4'
                          key={index}
                          initial={{ opacity: 0, x: -100 }} // Startposition unten außerhalb des sichtbaren Bereichs
                          animate={inView7 ? {opacity: 1,x:0, scale: 1} : {opacity: 0, x: -100 }} // Zielposition oben
                          transition={{ duration: 0.5, delay: index * 0.3 }} // Verzögerung basierend auf dem Index
                        > 
                          <div className='arrowHolder d-flex justify-content-center align-items-center'>
                            <FontAwesomeIcon icon={faArrowRight} className='bulletIcons'/>
                          </div>
                          <p className='bodytextBullets'>{stringsVisual}</p>
                        </motion.div>
                ))}
              </div>
            </div>
          </div>
          <div className='marginSection'>
            <div className='row align-items-end justify-content-start'>
              <div className='col-7'>
                <h2 className='text-start text-uppercase smallHeaderBereiche'>Neural Tools</h2>
                <p className='bodytext text-start'>{t("ndapp.Sec3_Text")}</p>
                <h2 className='text-start text-uppercase smallHeaderBereiche mt-5'>{t("ndapp.Sec4_Header")}</h2>
                <p className='bodytext text-start'>{t("ndapp.Sec4_Text")}</p>
              </div>
              <div className='col-5'>
                <img src={logoKogniNT} alt={altKogni} loading="lazy" title='Kognitionsspiele, Neural Tools' className='handy03'/>
              </div>
            </div>
          </div>
          <div className='marginSection'>
            <div className='row justify-content-center'>
              <div className='col-7'>
                <h2 className='text-center text-uppercase smallHeaderBereiche'>{t("ndapp.Sec5_Header")}</h2>
                <p className='bodytext text-center'>{t("ndapp.Sec5_Text")}</p>
                <div className='d-flex justify-content-center'>
                    <div ref={ref8} className='d-flex justify-content-evenly align-items-center breiteStars mt-3'>
                    {stars.map((star, index) => (
                      <motion.div
                        key={index}
                        initial={{ opacity: 0, y: 20, scale: 0.5 }} // Startposition unten außerhalb des sichtbaren Bereichs
                        animate={inView8 ? {opacity: 1,y:0, scale: 1} : {opacity: 0, y: 20, scale: 0.5}} // Zielposition oben
                        transition={{ duration: 0.3, delay: index * 0.2 }} // Verzögerung basierend auf dem Index
                      >
                        <FontAwesomeIcon icon={star} className="starIcon"/>
                      </motion.div>
                    ))}
                    </div>
                  </div>
                <motion.div ref={ref6} className="d-flex justify-content-center" variants={SchreibUnsVariants} animate={inView6 ? "animate" : "initial"} initial="initial">
                  <button className='buttonLoadPurchase text-uppercase'>{t("ndapp.Sec5_Button")}</button>
                </motion.div>
              </div>
            </div>
        </div>
      </div>
        /* Tablet Ansicht */
        ):responseTab ?(
          <NDAppTab/>
        /* Mobile Ansicht */
        ):responseMob ?(
          <NDAppMob/>
        ):null}
        </div>
    )
  }
