import React from 'react'
import { Spinner } from 'react-bootstrap'
import "../index.css"
import "bootstrap/dist/css/bootstrap.min.css"

export default function Loading() {
  return (
    <div className='container'>
        <div className="d-flex flex-row justify-content-center align-items-center mt-5">
            <Spinner animation="grow" />
            <h2 className='text-white '>Loading ...</h2>
        </div>
    </div>
  )
}
