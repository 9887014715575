import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css"
import { Carousel } from 'react-bootstrap'
import { useInView } from 'react-intersection-observer'
import { interactivity } from '../Animationen'
import { Link } from 'react-router-dom'
import { boxVariants,ScaleVariants,SchreibUnsVariants,ScaleVariantsSmooth } from '../Animationen'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChartSimple,faPen, faDiagramProject, faRocket, faArrowRightFromBracket, faUser} from '@fortawesome/free-solid-svg-icons';
import Lottie from "lottie-react"
import { motion } from 'framer-motion'
import computer from "../../assets/Animations/compAnimation.json"
import project from "../../assets/Animations/project.json"
import { handleClickBA, handleClickLars, handleClickND } from '../Clickables'
import { useTranslation } from 'react-i18next'

function NeuralDriveRepresentMob(){

    //Animationen Mobile
    const {t}=useTranslation();
    const {ref:refProjectHeader,inView:isInViewProjectHeader}=useInView({triggerOnce: true,threshold:0.6});
    const {ref:refMob1,inView:isInViewMob1}=useInView({triggerOnce: true});
    const {ref:refMob2,inView:isInViewMob2}=useInView({triggerOnce: true,threshold: 0.9});
    const {ref:refMob3,inView:isInViewMob3}=useInView({triggerOnce: true,threshold: 0.9});
    const {ref:refMob4,inView:isInViewMob4}=useInView({triggerOnce: true,threshold: 0.9});
    const {ref:refStarteJetztMob,inView:isInViewStarteJetztMob}=useInView({triggerOnce: true});
    const {ref:refBoxMob1,inView:isInViewStarteBoxMob1}=useInView({triggerOnce: true,threshold: 0.9});
    const {ref:refBoxMob2,inView:isInViewStarteBoxMob2}=useInView({triggerOnce: true,threshold: 0.9});
    const {ref:refBoxMob3,inView:isInViewStarteBoxMob3}=useInView({triggerOnce: true,threshold: 0.9});
    const {ref:refProjectBoxDesk,inView:isInViewProjectBoxDesk}=useInView({triggerOnce: true,threshold:0.7});
    
    return (   
          <div className="container marginSectionMob">
              <div className='d-flex align-items-center justify-content-center'>
                <Lottie animationData={computer} loop={false} className="img-fluid"/>
              </div>
              <h1 className='text-center text-uppercase gradHeaderDeskMob'>{t("represent.digitalSol")}</h1>
              <p className='bodytextMob text-center m20 pe-3 ps-3'>{t("represent.digitalSol_Text")}</p>
              <motion.div ref={refMob1} variants={SchreibUnsVariants} animate={isInViewMob1 ? "animate" : "initial"} initial="initial" className='d-flex justify-content-center mbot50'>
                <Link to="/contact">
                  <button className='buttonLoadPurchase text-uppercase'>{t("represent.WriteUS")}</button>
                </Link>  
              </motion.div>  
              <div className='marginSection'/>
              <div className='row d-flex align-items-center ps-3 pe-3'>           
                <motion.div ref={refMob2} variants={ScaleVariants} animate={isInViewMob2 ? "animate" : "initial"} initial="initial" className='col-12 angebotBoxMob d-flex flex-column justify-content-center align-items-center'>
                  <div className='iconContainerDesk d-flex justify-content-center align-items-center'>
                    <FontAwesomeIcon icon={faChartSimple} className="iconsOverViewBig"/>
                  </div>
                  <p className='smallHeaderBereicheMob m10'>{t("represent.OnlinePresence")}</p>
                  <p className='bodytext text-center'>{t("represent.Represent_Text_Overview_01")}</p>
                </motion.div>              
                <motion.div ref={refMob3} variants={ScaleVariants} animate={isInViewMob3 ? "animate" : "initial"} initial="initial" className='col-12 angebotBoxMob mt-5 mb-5 d-flex flex-column justify-content-center align-items-center marginSectionMob'>
                  <div className='iconContainerDesk d-flex justify-content-center align-items-center'>
                    <FontAwesomeIcon icon={faPen} className="iconsOverViewBig"/>
                  </div>
                  <p className='smallHeaderBereicheMob m10'>Design</p>
                  <p className='bodytext text-center'>{t("represent.Represent_Text_Overview_02")}</p>
                </motion.div>          
                <motion.div ref={refMob4} variants={ScaleVariants} animate={isInViewMob4 ? "animate" : "initial"} initial="initial" className='col-12 angebotBoxMob d-flex flex-column justify-content-center align-items-center marginSectionMob'>
                  <div className='iconContainerDesk d-flex justify-content-center align-items-center'>
                    <FontAwesomeIcon icon={faDiagramProject} className="iconsOverViewBig"/>
                  </div>
                  <p className='smallHeaderBereicheMob m10'>{t("represent.Application")}</p>
                  <p className='bodytext text-center'>{t("represent.Represent_Text_Overview_03")}</p>
                </motion.div>
            </div>
            <h2 className='text-center text-uppercase HeaderDeskMob m20 pt80'>{t("represent.Represent_Header_Sec2_01")}<br/>{t("represent.Represent_Header_Sec2_02")}</h2>
            <p className='bodytextMob text-center m20 pe-3 ps-3'>{t("represent.Represent_Text_Sec2")}</p>
            <motion.div ref={refStarteJetztMob} variants={SchreibUnsVariants} animate={isInViewStarteJetztMob ? "animate" : "initial"} initial="initial" className='d-flex justify-content-center'> 
                <Link to="/contact">                
                  <button className='buttonCTA d-flex flex-row justify-content-center align-items-center text-uppercase'>
                  {t("represent.Represent_Button_Sec2")}
                    <FontAwesomeIcon icon={faRocket} style={{ marginLeft: '15px', color:"white"}} />
                  </button>
                </Link>
            </motion.div>
            <div className='d-flex align-items-center justify-content-center m40'>
              <Lottie animationData={project} loop={true} interactivity={interactivity} className="img-fluid"/>
            </div>
            <div className='marginSectionMob'/>
            <div ref={refProjectHeader} className='row'>
              <div className='col-12 align-items-center d-flex flex-column'>
                <motion.h2 variants={SchreibUnsVariants} animate={isInViewProjectHeader ? "animate" : "initial"} initial="initial" className='text-center text-uppercase HeaderDeskMob'>NeuralDrive {t("represent.Represent_Header_Sec3")}</motion.h2>
                {/*<p className='bodytext m20 text-center w-75'>Wir konnten für unsere Kunden schon einige Ideen in die Realität umsetzen. Hier sind einige Beispielprojekte aufgeführt. Darunter fällt eine Portfolio-Seite, eine Web-App zur Trainingsplanverwaltung und unsere eigens entwickelte App für neurozentriertes Training. Schaut doch mal vorbei.</p>*/}
              </div>
              <motion.div ref={refProjectBoxDesk} animate={isInViewProjectBoxDesk ? "animate" : "initial"} variants={ScaleVariantsSmooth} >
              <Carousel controls={false} indicators={true} className='align-items-center mt-5'>
                <Carousel.Item>
                <div className='btnProjects d-flex flex-column justify-content-center align-items-center ms-2 me-2'>
                    <h4 className='smallHeaderBereicheMob text-center mt-3'>NeuralDrive App</h4>
                    <p className='bodytextMob text-center mt-3 w-100 pe-2 ps-2'>
                    {t("represent.Represent_Text_Sec3_NDApp")}
                    </p>
                    <button className='buttonProjects d-flex flex-row justify-content-center align-items-center mt-4 mb-3' onClick={handleClickND}>
                        App
                        <FontAwesomeIcon icon={faArrowRightFromBracket} style={{ marginLeft: '15px'}} className="iconProjectBtn"/>
                    </button>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                <div className='btnProjects d-flex flex-column justify-content-center align-items-center ms-2 me-2'>
                  <h4 className='smallHeaderBereicheMob text-center mt-3'>Highflyerz Academy</h4>
                  <p className='bodytextMob text-center w-100 mt-3 pe-2 ps-2'>
                  {t("represent.Represent_Text_Sec3_HF")}
                  </p>
                  <button className='buttonProjects d-flex flex-row justify-content-center align-items-center mt-4 mb-3'>
                    Web-App
                    <FontAwesomeIcon icon={faArrowRightFromBracket} style={{ marginLeft: '15px'}} className="iconProjectBtn" />
                  </button>
                </div>
                </Carousel.Item>
                <Carousel.Item>
                <div className='btnProjects d-flex flex-column justify-content-center align-items-center ms-2 me-2'>
                  <h4 className='smallHeaderBereicheMob text-center mt-3'>Lars Grießig</h4>
                  <p className='bodytextMob text-center w-100 mt-3 pe-2 ps-2'>
                    {t("represent.Represent_Text_Sec3_LG")}
                  </p>
                  <button className='buttonProjects d-flex flex-row justify-content-center align-items-center mt-4 mb-3' onClick={handleClickLars}>
                    Website
                    <FontAwesomeIcon icon={faArrowRightFromBracket} style={{ marginLeft: '15px'}} className="iconProjectBtn"/>
                  </button>
                </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className='btnProjects d-flex flex-column justify-content-center align-items-center ms-2 me-2'>
                    <h4 className='smallHeaderBereicheMob text-center mt-3'>Bewegungsarchitekten</h4>
                    <p className='bodytextMob text-center w-100 mt-3 pe-2 ps-2'>
                      {t("represent.Represent_Text_Sec3_BA")}
                    </p>
                    <button className='buttonProjects d-flex flex-row justify-content-center align-items-center mt-4 mb-3' onClick={handleClickBA}>
                      Website
                      <FontAwesomeIcon icon={faArrowRightFromBracket} style={{ marginLeft: '15px'}} className="iconProjectBtn"/>
                    </button>
                  </div>
                </Carousel.Item>
            </Carousel>
            </motion.div>
            </div>
            <h2 className='text-center HeaderDeskMob marginSectionMob mb-5'>NeuralDrive Team</h2>
            <div className='d-flex justify-content-center'>
              <div className='d-flex flex-column justify-content-between align-items-center w-100'>
                  <div className='d-flex flex-column justify-content-start align-items-center mbot50'>
                    <motion.div ref={refBoxMob1} variants={boxVariants} animate={isInViewStarteBoxMob1 ? "animate" : "initial"} initial="initial" className='d-flex justify-content-center align-items-center imgPersonContainer'>
                      <FontAwesomeIcon icon={faUser} style={{color:"white"}} className="imgPerson"/>
                    </motion.div>
                    <p className='smallHeaderBereicheMob text-center m20'>Tom Erlemann</p>
                    <p className='bodytextMob text-center'>UI Design</p>
                    <p className='bodytextMob text-center'>Web Design</p>
                  </div>
                  <div className='d-flex flex-column justify-content-start align-items-center mbot50'>
                    <motion.div ref={refBoxMob2} variants={boxVariants} animate={isInViewStarteBoxMob2 ? "animate" : "initial"} initial="initial" className='d-flex justify-content-center align-items-center imgPersonContainer'>
                      <FontAwesomeIcon icon={faUser} style={{color:"white"}} className="imgPerson"/>
                    </motion.div>
                    <p className='smallHeaderBereicheMob text-center m20'>Simon Stephan</p>
                    <p className='bodytextMob text-center'>{t("represent.Represent_Text_Sec4_SS_01")}</p>
                    <p className='bodytextMob text-center'>{t("represent.Represent_Text_Sec4_SS_02")}</p>
                  </div>
                  <div className='d-flex flex-column justify-content-start align-items-center mbot50'>
                    <motion.div ref={refBoxMob3} variants={boxVariants} animate={isInViewStarteBoxMob3 ? "animate" : "initial"} initial="initial" className='d-flex justify-content-center align-items-center imgPersonContainer'>
                      <FontAwesomeIcon icon={faUser} style={{color:"white"}} className="imgPerson"/>
                    </motion.div>
                    <p className='smallHeaderBereicheMob text-center m20'>Gadschi Saidov</p>
                    <p className='bodytextMob text-center'>Corporate Design</p>
                    <p className='bodytextMob text-center'>3D Design</p>
                  </div>
              </div>
            </div>
          </div>
    )
  };
  
  export default NeuralDriveRepresentMob;
