export const handleClickLars = () => {
    window.open('https://larsgriessig-neurotraining.de/', '_blank', 'noopener,noreferrer');
  };

export const handleClickND = () => {
window.open('https://apps.apple.com/de/app/neuraldrive/id1511421714', '_blank', 'noopener,noreferrer');
}

export const handleClickBA = () => {
  window.open('https://www.bewegungsarchitekten.berlin/', '_blank', 'noopener,noreferrer');
}

export const handleClickAppstore = () => {
  window.open('https://apps.apple.com/de/app/neuraldrive/id1511421714', '_blank', 'noopener,noreferrer');
  // eslint-disable-next-line no-undef
  //return gtag_report_conversion("https://apps.apple.com/de/app/neuraldrive/id1511421714");
}

export const handleClickGooglePlay = () => {
  window.open('https://play.google.com/store/apps/details?id=com.NeuralDrive.TSGbR&hl=de&gl=US', '_blank', 'noopener,noreferrer');
}

